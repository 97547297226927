html,
body,
div {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

.checkbox-label {
  padding-left: 5px;
}

.hidden-spinner {
  display: none;
}

.hidden-button {
  display: none;
}

.config-content {
  width: 100%;
  display: block;
}

.config-background {
  display: block;
  position: absolute;
  z-index: -1;
  top: 0px;
  width: 100%;
  height: 300px;
  background-color: rgb(33, 47, 63);
}

.config-container {
  display: block;
  height: auto;
  min-height: 65vh;
  margin: 2rem auto 0px;
  padding: 2rem 3rem;
  max-width: 768px;
  background-color: rgb(255, 255, 255);
  z-index: 2;
  box-shadow: rgb(0 0 0 / 10%) 0px 0px 20px;
  border-radius: 2px;
}

.config-footer {
  width: 100%;
  padding-bottom: 10px;
}

.config-footer img {
  height: 70px;
  display:block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.dashboard-link {
  margin-left: 25px;
}